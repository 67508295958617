var vm = require('./common/VueModel.js');
var $ = require('jquery');
var stickyFooter = require('./common/stickyFooter');
var offCanvasMenu = require('./common/offCanvasMenu');
var fixedHeaderBorder = require('./home/fixedHeaderBorder');
var loginPopup = require('./common/loginPopup');
var headerNavDropdowns = require('./common/headerNavDropdowns');
var searchCircles = require('./search/searchCircles');
var floatingHeaderScrollSwitch = require('./common/floatingHeaderScrollSwitch');
var cookiePolicy = require('./common/cookiePolicy');

$(document).ready(function(){

    //////////////// On Ready ////////////////

    var oldIE;
    if ($('html').is('.ie6, .ie7, .ie8')) {
        oldIE = true;
    }

    stickyFooter();
    offCanvasMenu();
    loginPopup();
    headerNavDropdowns();
    cookiePolicy();

    if (!oldIE) {
        searchCircles();
    };

    $(".js-no-widows").each(function() {
        var wordArray = $(this).text().split(" ");
        if (wordArray.length > 1) {
            wordArray[wordArray.length-2] += "&nbsp;" + wordArray[wordArray.length-1];
            wordArray.pop();
            $(this).html(wordArray.join(" "));
        }
    });

    $('.anchor-link').on('click', function(event) {
        var target = $($(this).attr('href'));

        if( target.length ) {
            $('html, body').animate({
                scrollTop: target.offset().top - 80
            }, 1000);
        }

        event.preventDefault();
    });

    var $window = $(window);

    //////////////// On Resize ////////////////

    $window.resize(function() {
        stickyFooter();
    });

    //////////////// On Scroll ////////////////

    $window.scroll(function() {
        if (typeof floatingHeader !== 'undefined' && floatingHeader) floatingHeaderScrollSwitch($window);
    });

});


